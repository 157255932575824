<template>
  <span>
    <img
      :src="currency.wallet.currencyIcon"
      class="currency-icon"
      alt="currency-icon"
    />
    <span
      >{{ currency.wallet.currencyName }}
      {{ currency.wallet.currencyCode }}</span
    >
  </span>
</template>

<script>
export default {
  name: "currency-item",
  props: { currency: {} }
};
</script>

<style lang="scss" scoped>
.currency-icon {
  display: inline-block;
  width: 20px;
  margin-right: 0.5rem;
}
</style>
