<template>
  <div class="col-12 col-md-4 mt-md-0 mt-4">
    <b-button
      variant="outline-success"
      block
      :disabled="disabled"
      @click="scanQRCode"
    >
      <span>{{
        $t(
          "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.MIRACLE_CUSTOMER"
        )
      }}</span>
    </b-button>
    <b-modal
      id="qr-reader-modal"
      v-model="startScan"
      @hidden="modalClosed"
      centered
      hide-footer
      modal-class="body-class"
    >
      <template #modal-header="{ close }">
        <h2 class="m-0 w-100 text-primary w-100 text-center font-weight-bolder">
          {{ displayDuration }}
        </h2>

        <button type="button" aria-label="Close" @click="close()" class="close">
          ×
        </button>
      </template>
      <qrcode-stream
        :camera="camera"
        @decode="onDecode"
        :track="paintOutline"
        @init="onInit"
      >
        <div
          class="d-flex flex-1 align-items-center justify-content-center h-100"
          v-if="loading"
        >
          <b-icon
            icon="camera"
            font-scale="8"
            variant="warning"
            animation="cylon"
            class="mb-4"
          ></b-icon>
        </div>
      </qrcode-stream>
    </b-modal>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: { QrcodeStream },
  props: ["disabled"],
  data() {
    return {
      loading: false,
      camera: "auto",
      startScan: false,
      result: null,
      timer: null,
      scanDuration: 0,
      displayDuration: "05:00"
    };
  },
  methods: {
    startTimer() {
      this.scanDuration = 1000 * 60 * 5;
      this.checkRemaining();
      this.timer = setInterval(this.checkRemaining, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.scanDuration = 0;
      this.displayDuration = "05:00";
      this.startScan = false;
    },
    checkRemaining() {
      this.scanDuration -= 1000;
      this.displayDuration = this.$moment(this.scanDuration).format("mm:ss");

      if (this.scanDuration <= 0) {
        this.stopTimer();
      }
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";
        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    scanQRCode() {
      this.startScan = true;
      this.turnCameraOn();
    },
    async onInit(promise) {
      this.loading = true;

      try {
        await promise;
        this.startTimer();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();
      this.startScan = false;
    },
    turnCameraOn() {
      this.camera = "auto";
    },
    turnCameraOff() {
      this.camera = "off";
    },
    modalClosed() {
      this.stopTimer();
      this.$emit("qrcode-scanned", this.result);
    }
  }
};
</script>

<style lang="scss">
.body-class {
  &.modal {
    .modal-dialog {
      height: 100%;

      .modal-content {
        height: 75%;

        .modal-body {
          padding: 0;
        }
      }
    }
  }
}

.modal-header {
  h2 {
    height: 27px;
    max-height: 27px;
  }
}

.z-index-100 {
  z-index: 100;
}
</style>
