<template>
  <div
    class="dropdown-container position-static no-focus d-inline-flex col-12 col-md-4 col-md"
  >
    <b-dropdown
      block
      id="currency-dropdown"
      menu-class="dropdown-menu-scroll flex-1"
      variant="outline-primary"
      lazy
    >
      <template #button-content>
        <currency-item
          v-if="selectedCurrency"
          :currency="selectedCurrency"
        ></currency-item>
        <span v-else>{{ $t("FORM.SELECT_A_CURRENCY_UNIT") }}</span>
      </template>
      <b-dropdown-item-button
        v-for="currency in currencies"
        :key="currency.id"
        @click="$emit('currency-selected', currency)"
      >
        <currency-item :currency="currency"></currency-item>
      </b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>

<script>
import CurrencyItem from "./CurrencyItem.vue";

export default {
  components: { CurrencyItem },
  props: { currencies: [], selectedCurrency: {} }
};
</script>

<style lang="scss">
$border-color: 1px solid #ebedf3;

#currency-dropdown {
  position: initial;
  flex: 1;
}

.no-focus {
  .btn.btn-outline-primary:focus:not(.btn-text),
  .btn.btn-outline-primary.focus:not(.btn-text) {
    color: #3699ff;
    background-color: transparent;
    border-color: #3699ff;
  }
}

.dropdown-menu-scroll {
  padding: 0;
  flex-wrap: wrap;
  width: 100%;
  z-index: 150;
  // height: 70vh;
  overflow-y: scroll;
  padding-inline-start: 0 !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  box-sizing: border-box;

  &.show {
    display: flex;
    // transform: translate3d(0, 0, 0) !important;
  }

  > li {
    @media (min-width: 768px) {
      width: 25%;
    }
    width: 50%;
    border-bottom: $border-color;
    border-right: $border-color;
  }
}
</style>
